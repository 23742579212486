import Client from "../client/Client"
import version from "../version"
const resource = version + "/staff/config"
const resourceEmail = version + "/staff/setting/email-setting"
const resourcePayment = version + "/staff/setting/offline-payment"
const resourceHistory = version + "/staff/setting/system-maintenance"

// Overview
function get(key) {
  return Client.get(`${resource}/${key}/`)
}
function getAllSetting(params) {
  return Client.get(`${version}/staff/configs/`, { params })
}
function update(key, data) {
  return Client.post(`${resource}/${key}/upsert/`, data)
}

//Email Setting
function getListEmails(params) {
  return Client.get(`${resourceEmail}/`, { params })
}
function getDetailEmail(id) {
  return Client.get(`${resourceEmail}/${id}/`)
}
function createEmail(data) {
  return Client.post(`${resourceEmail}/`, data)
}
function updateEmail(id, data) {
  return Client.put(`${resourceEmail}/${id}/`, data)
}

// Payment Method
function getListPayment(params) {
  return Client.get(`${resourcePayment}/`, { params })
}
function getDetailPayment(id) {
  return Client.get(`${resourcePayment}/${id}/`)
}
function createPayment(data) {
  return Client.post(`${resourcePayment}/`, data)
}
function updatePayment(id, data) {
  return Client.put(`${resourcePayment}/${id}/`, data)
}
function removePayment(id) {
  return Client.delete(`${resourcePayment}/${id}/`)
}

// system maintenance
function getListHistory(params) {
  return Client.get(`${resourceHistory}/history/`, { params })
}

function getDashboard(params) {
  return Client.get(`${version}/staff/dashboard/`, { params })
}
function getDashboardRegisrer(params) {
  return Client.get(`${version}/staff/dashboard/client-register-count/`, { params })
}
function getDashboardRevenue(params) {
  return Client.get(`${version}/staff/dashboard/revenue-by-year/`, { params })
}
const api = {
  get,
  getAllSetting,
  update,
  getListEmails,
  getDetailEmail,
  createEmail,
  updateEmail,
  getListPayment,
  getDetailPayment,
  createPayment,
  updatePayment,
  removePayment,
  getListHistory,
  getDashboard,
  getDashboardRegisrer,
  getDashboardRevenue
}
export default api
