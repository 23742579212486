import Client from "../client/Client"
import version from "../version"
const resource = version + "/staff/clients"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function getsInternal(params) {
  return Client.get(`${resource}/`, { params })
}
function get(id, params) {
  return Client.get(`${resource}/${id}/`, { params })
}
function exportList(data) {
  return Client.post(`${resource}/export-csv/`, data)
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function remove(id, data) {
  return Client.delete(`${resource}/${id}/`, data)
}
function getBrands(params) {
  return Client.get(`${version}/staff/organizations/all/`, { params })
}
function getPaymentMethods(client_id) {
  return Client.get(`${resource}/${client_id}/billing/payment-methods/`)
}
function updateStatus(id, data) {
  return Client.put(`${resource}/${id}/change-status/`, data)
}
function resetPassword(id, data) {
  return Client.put(`${resource}/${id}/reset-password/`, data)
}
const api = {
  gets,
  getsInternal,
  get,
  exportList,
  create,
  update,
  remove,
  getBrands,
  getPaymentMethods,
  updateStatus,
  resetPassword
}

export default api
