// import { useEffect, useState } from "react"
import { Modal, Button, Image } from "antd"
import successImage from "assets/images/successImage.png"

export default function ModalPopupConfirm({
  visible,
  image = successImage,
  title,
  desctiption,
  titleButton = "Continue",
  loading,
  onClose,
  onSubmit
}) {
  return (
    <Modal
      title={null}
      centered
      width={400}
      open={visible}
      onCancel={onClose}
      onOk={onSubmit}
      footer={null}
      closable={true}
      maskClosable={true}
    >
      <div>
        <div className="mt-5 flex justify-center">
          <Image width={180} height={180} preview={false} src={image} />
        </div>
        <div className="my-3 mx-[16px] font-bold text-center">{title}</div>
        <div className="mx-[16px] text-[#646970] text-center">{desctiption}</div>
        <div className="mt-5">
          <Button className="w-full" type="primary" loading={loading} onClick={onSubmit}>
            {titleButton}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
