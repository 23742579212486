import { Result } from "antd"
import { useEffect } from "react"
import axios from "axios"
import { getBaseDataLocal } from "lib/localstorage"
import { useNavigate } from "react-router-dom"

const baseDomain = process.env.REACT_APP_ID_URL

export default function MaintenancePage() {
  const navigate = useNavigate()
  const { access_token } = getBaseDataLocal()

  // function fetchProfile() {
  //   axios({
  //     url: `${baseDomain}/api/v1/account/view/profile/`,
  //     method: "get",
  //     headers: {
  //       Authorization: `Bearer ${access_token}`,
  //       "Content-Type": "application/json"
  //     }
  //   })
  //     .then((response) => {
  //       console.log(response.data)
  //       navigate("/dashboard")
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     })
  // }

  useEffect(() => {
    // fetchProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="__content">
      <Result
        status="500"
        title="500"
        subTitle="Sorry, The system is currently maintenance. Please come back later."
      />
    </div>
  )
}
