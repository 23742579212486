import axios from "axios"
import createAuthRefreshInterceptor from "axios-auth-refresh"
import { notification } from "antd"
import { getCookieAuth, removeCookies, setCookieAuth } from "lib/cookie"
import { getCurrentTimezone } from "lib/function"
import i18n from "../i18n"

const refreshAuthLogic = (failedRequest) =>
  axios
    .post(`${process.env.REACT_APP_ID_URL}/api/v1/account/refresh/`, {
      refresh_token: getCookieAuth()?.refresh_token
    })
    .then((tokenRefreshResponse) => {
      const newAccessToken = tokenRefreshResponse.data.data.access_token
      setCookieAuth({
        access_token: newAccessToken,
        refresh_token: getCookieAuth()?.refresh_token
      })
      failedRequest.response.config.headers["Authorization"] = "Bearer " + newAccessToken
      return Promise.resolve()
    })
    .catch((error) => {
      localStorage.clear()
      removeCookies()
      // axios.delete("/auth/register", { token: getRefreshToken() })
      window.location.href = "/login"
    })

export default function getInstanceAxios(baseAPI, method, isHideMessage) {
  const instance = axios.create({
    // withCredentials: true,
    baseURL: baseAPI
  })
  createAuthRefreshInterceptor(instance, refreshAuthLogic)
  instance.interceptors.request.use(
    async function (config) {
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        method: method,
        Timezone: getCurrentTimezone(),
        Location: localStorage.getItem("location"),
        Language: i18n.language
      }
      const { access_token } = getCookieAuth()
      if (access_token) headers["Authorization"] = `Bearer ${access_token}`
      config.headers = headers
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    function (response) {
      try {
        if (response.status >= 200 && response.status < 300) return response.data
        return Promise.reject(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async function (error) {
      if (error.response) {
        const { response } = error

        const data = response.data
        if (data && response.config.method !== "get" && response.status !== 500 && !isHideMessage) {
          if (data.error_message) {
            notification.error({
              description: convertMessageError(data.error_message)
            })
            return Promise.reject(data.error_message)
          }
          if (data.message) {
            notification.error({
              description: i18n.t(data?.message || data?.error_message)
            })
            return
          }
          if (data && !data.message && Object.values(data).length > 0) {
            if (data.length > 0) {
              data.forEach((item) => {
                notification.error({
                  description: i18n.t(item)
                })
              })
            } else {
              Object.values(data).forEach((item) => {
                notification.error({
                  description: i18n.t(item?.length > 0 ? item[0] : "N/A")
                })
              })
            }
          } else {
            notification.error({
              description: i18n.t(data?.message || data?.error_message)
            })
          }
        }
      }
      if (error?.response?.data?.error_code === "ERR_MNT_503") {
        if (window.location.pathname !== "/maintenance") {
          window.location.href = "/maintenance"
        }
      } else {
        if (error?.response?.status >= 500) {
          if (window.location.pathname !== "/error-5xx") {
            window.location.href = "/error-5xx"
          }
        }
      }
      console.log("ppp", error)

      return Promise.reject(error)
    }
  )

  return instance
}

function convertMessageError(errors, t) {
  var str = []
  for (let i = 0; i < errors.length; i++) {
    if (typeof errors[i] === "string") {
      str.push(`${errors[i]}.`)
    } else {
      let k = Object.keys(errors[i])[0]
      str.push(k + " : " + errors[i][k])
    }
  }
  return str.map((i) => {
    return <div>{i18n.t(i)}</div>
  })
}
