import { RESOURCE_KEY } from "lib/Const"
import { lazy } from "react"

const Dashboard = lazy(() => import("pages/Dashboard"))
const UserProfile = lazy(() => import("pages/UserProfile"))
const ClientManagement = lazy(() => import("pages/ClientManagement"))
const Billing = lazy(() => import("pages/Billing"))
const BillingDetail = lazy(() => import("pages/Billing/BillingDetail"))
const UpdateClientManagement = lazy(() => import("pages/ClientManagement/UpdateClientManagement"))
const ViewClientManagement = lazy(
  () => import("pages/ClientManagement/Detail/ViewClientManagement")
)
const PackagesManagement = lazy(() => import("pages/PackagesManagement"))
const UpdatePackagesManagement = lazy(
  () => import("pages/PackagesManagement/UpdatePackagesManagement")
)
const ViewPackagesManagement = lazy(() => import("pages/PackagesManagement/ViewPackagesManagement"))
const RoleAndPermissionSetting = lazy(() => import("pages/UserManagement/RoleAndPermissionSetting"))
const DetailPermissonSetting = lazy(
  () => import("pages/UserManagement/RoleAndPermissionSetting/DetailPermissonSetting")
)
const UserList = lazy(() => import("pages/UserManagement/UserList"))
const UpdateUser = lazy(() => import("pages/UserManagement/UserList/UpdateUser"))
const Domain = lazy(() => import("pages/Domain"))
const ApprovalList = lazy(() => import("pages/ApprovalList"))
const ActivityLog = lazy(() => import("pages/ActivityLog"))
const SendNotification = lazy(() => import("pages/SendNotification"))
const Segment = lazy(() => import("pages/Segment"))
const MyNotification = lazy(() => import("pages/MyNotification"))

// Settings
const GeneralSetting = lazy(() => import("pages/Settings/GeneralSetting"))
const ApplicationSetting = lazy(() => import("pages/Settings/ApplicationSetting"))
const BillingSetting = lazy(() => import("pages/Settings/BillingSetting"))
const SecuritySetting = lazy(() => import("pages/Settings/SecuritySetting"))
const EmailSetting = lazy(() => import("pages/Settings/EmailSetting"))
const CreateEmailSetting = lazy(() => import("pages/Settings/EmailSetting/UpdateEmail"))
const PaymentMethod = lazy(() => import("pages/Settings/PaymentMethod"))
const ThemeSetting = lazy(() => import("pages/Settings/ThemeSetting"))
const CustomDomainSetting = lazy(() => import("pages/Settings/CustomDomainSetting"))
const SystemMaintenance = lazy(() => import("pages/Settings/SystemMaintenance"))
const ApprovalSetting = lazy(() => import("pages/Settings/ApprovalSetting"))
const UpdateApprovalSetting = lazy(() => import("pages/Settings/ApprovalSetting/UpdateApproval"))
const SupportTicket = lazy(() => import("pages/SupportTicket"))
const ViewSupportTicket = lazy(() => import("pages/SupportTicket/ViewSupportTicket"))

//____ListPage
function pageList(__role) {
  return [
    {
      path: "/",
      Element: Dashboard,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "dashboard",
      pageName: "Dashboard",
      Element: Dashboard,
      resource: RESOURCE_KEY.DASHBOARD
    },
    {
      path: "/client",
      Element: ClientManagement,
      resource: RESOURCE_KEY.CLIENT
    },
    {
      path: "/billing",
      Element: Billing,
      resource: RESOURCE_KEY.BILLING
    },
    {
      path: "client/action",
      Element: UpdateClientManagement,
      resource: RESOURCE_KEY.CLIENT
    },
    {
      path: "client/action/view",
      Element: ViewClientManagement,
      resource: RESOURCE_KEY.CLIENT
    },
    {
      path: "/packages",
      Element: PackagesManagement,
      resource: RESOURCE_KEY.PACKAGE
    },
    {
      path: "packages/action",
      Element: UpdatePackagesManagement,
      resource: RESOURCE_KEY.PACKAGE
    },
    {
      path: "packages/action/view",
      Element: ViewPackagesManagement,
      resource: RESOURCE_KEY.PACKAGE
    },
    {
      path: "/user/roles",
      Element: RoleAndPermissionSetting,
      resource: RESOURCE_KEY.USER_ROLE
    },
    {
      path: "/user/roles/action/view",
      Element: DetailPermissonSetting,
      resource: RESOURCE_KEY.USER_ROLE
    },
    {
      path: "/user/users",
      Element: UserList,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/user/users/action",
      Element: UpdateUser,
      resource: RESOURCE_KEY.USER_USERS
    },
    {
      path: "/domain",
      Element: Domain,
      resource: RESOURCE_KEY.DOMAIN
    },
    {
      path: "/approval",
      Element: ApprovalList,
      resource: RESOURCE_KEY.APPROVAL
    },
    {
      path: "/profile",
      Element: UserProfile,
      resource: RESOURCE_KEY.USER_PROFILE
    },
    // Settings
    {
      path: "/setting/general",
      Element: GeneralSetting,
      resource: RESOURCE_KEY.SETTING_GENERAL
    },
    {
      path: "/setting/application-setting",
      Element: ApplicationSetting,
      resource: RESOURCE_KEY.SETTING_APPLICATION
    },
    {
      path: "/setting/bill-setting",
      Element: BillingSetting,
      resource: RESOURCE_KEY.SETTING_BILLING
    },
    {
      path: "/setting/security-setting",
      Element: SecuritySetting,
      resource: RESOURCE_KEY.SETTING_SECURITY
    },
    {
      path: "/setting/email-setting",
      Element: EmailSetting,
      resource: RESOURCE_KEY.SETTING_EMAIL
    },
    {
      path: "/setting/email-setting/action",
      Element: CreateEmailSetting,
      resource: RESOURCE_KEY.SETTING_EMAIL
    },
    // {
    //   path: "/setting/payment-method",
    //   Element: PaymentMethod,
    //   resource: RESOURCE_KEY.CLIENT
    // },
    {
      path: "/setting/theme-setting",
      Element: ThemeSetting,
      resource: RESOURCE_KEY.SETTING_THEME
    },
    {
      path: "/setting/custom-domain-setting",
      Element: CustomDomainSetting,
      resource: RESOURCE_KEY.CLIENT
    },
    {
      path: "/setting/system-maintenance",
      Element: SystemMaintenance,
      resource: RESOURCE_KEY.SETTING_SYSTEM
    },
    {
      path: "/setting/appr-setting",
      Element: ApprovalSetting,
      resource: RESOURCE_KEY.SETTING_APPROVAL
    },
    {
      path: "/setting/appr-setting/action",
      Element: UpdateApprovalSetting,
      resource: RESOURCE_KEY.SETTING_APPROVAL
    },
    //
    {
      path: "billing/action/view",
      Element: BillingDetail,
      resource: RESOURCE_KEY.BILLING
    },
    {
      path: "activity-logs",
      Element: ActivityLog,
      resource: RESOURCE_KEY.ACTIVITY_LOG
    },
    {
      path: "/notification/send-notification",
      Element: SendNotification,
      resource: RESOURCE_KEY.NOTIFICATION_LIST
    },
    {
      path: "/notification/segment",
      Element: Segment,
      resource: RESOURCE_KEY.NOTIFICATION_SEMENT
    },
    {
      path: "/my-notification",
      Element: MyNotification,
      resource: RESOURCE_KEY.MY_NOTIFICATION
    },
    {
      path: "/support-ticket",
      Element: SupportTicket,
      resource: RESOURCE_KEY.SUPPORT_TICKET
    },
    {
      path: "/support-ticket/action",
      Element: ViewSupportTicket,
      resource: RESOURCE_KEY.SUPPORT_TICKET
    }
  ]
}

export default pageList
