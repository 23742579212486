export const RESOURCE_KEY = {
  CLIENT: "view_client",
  DASHBOARD: "view_dashboard",
  BILLING: "view_billing",
  BILLING_STATIC: "view_billing_statistic",
  PACKAGE: "view_package",
  USER: "user",
  USER_ROLE: "view_group",
  USER_USERS: "view_user",
  USER_PROFILE: "view_user_profile",
  DOMAIN: "view_domain",
  APPROVAL: "view_approval",
  NOTIFICATION: "vnotification",
  NOTIFICATION_LIST: "view_notification",
  NOTIFICATION_SEMENT: "view_segment",
  MY_NOTIFICATION: "view_my_notification",
  SUPPORT_TICKET: "view_support_ticket",
  SETTING: "setting",
  SETTING_GENERAL: "view_general_setting",
  SETTING_APPLICATION: "view_application_setting",
  SETTING_EMAIL: "view_email_setting",
  SETTING_BILLING: "view_billing_setting",
  SETTING_APPROVAL: "view_approval_setting",
  SETTING_SECURITY: "view_security_setting",
  SETTING_THEME: "view_theme_setting",
  SETTING_SYSTEM: "view_system_maintenance_setting",
  ACTIVITY_LOG: "view_activity_log"
}

export const HERE_MAP_KEY = "9mYmeeYB-3xOPR2-L0l1-S1ZNw5NYAZtVUGnKluRTEU"
export const HERE_MAP_APP_ID = "Ayeaqmdzcy9BqxvGdf44"
export const SERVICE_CODE = {
  API_CONTROL_PLANE: "api-control-plane",
  API_CHAUFFEUR: "	api-chauffeur-connect",
  API_ASSET_CONTROL: "api-asset-control",
  EXTERNAL_API_CHAUFFEUR: "chauffeur-connect",
  EXTERNAL_API_ASSET_CONTROL: "asset-control",
  API_OM_API_GATEWAY: "api-om-api-gateway",
  API_IDM: "api-idm",
  API_AMS: "api-ams",
  API_COMMOM: "api-common",
  API_NOTI_INAPP: "api-notification-inapp",
  API_IOT_ACTIVITY: ""
}

export const ENVIRONMENT = [
  {
    label: "Development",
    value: "development"
  },
  {
    label: "Testing",
    value: "testing"
  },
  {
    label: "Staging",
    value: "staging"
  },
  {
    label: "Production",
    value: "production"
  }
]

export const configLocale = {
  designFileId: process.env.REACT_APP_TRANSLATE_DESIGN_FILE,
  projectId: process.env.REACT_APP_TRANSLATE_PROJECT_ID
}

export const PLATFORM = [
  {
    label: "Web",
    value: "web"
  },
  {
    label: "Mobile",
    value: "mobile"
  }
]

export const ACTION_LOGS = [
  {
    label: "46_10888",
    value: "create"
  },
  {
    label: "46_10889",
    value: "update"
  },
  {
    label: "46_10890",
    value: "delete"
  }
]

export const TYPE_BLOCK_NOTI = {
  APPROVAL: 1,
  NEWS: 2,
  MARKETING: 3,
  PROMOTION: 4,
  EVENT: 5,
  TASK: 6
}

export const Approval_Status = {
  pending: 0,
  approved: 1,
  rejected: 2
}
export const GENDER_TYPE = {
  MALE: "MALE",
  FEMALE: "FEMALE"
}

export const ORGANIZATION_SETTING_TYPE = {
  adminPassword: 1,
  enduserPassword: 2,
  accessLimit: 3,
  otp: 4
}

export const REPORT_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  DISAPPROVED: 2
}

export const REPORT_TYPES = {
  ABSENCE: 0,
  EXPENSE: 1
}

export const ACTIVITY_TYPE = {
  RIDE: 0,
  MEETING: 1,
  AIRPORT: 2,
  RENTAL: 3,
  PACKAGE: 4
}

export const ACTIVITY_TYPE_MAP = ["Ride", "Meeting", "Airport", "Rental", "Package"]

export const Setting_Password_Policy = {
  allow_special_character: "allow_special_character",
  alphabetic_letter_required: "alphabetic_letter_required",
  lowercase_letter_required: "lowercase_letter_required",
  uppercase_letter_required: "uppercase_letter_required",
  numbers_required: "numbers_required",
  password_min_length: "password_min_length",
  password_max_length: "password_max_length",
  allowed_character_checked: "allowed_character_checked",
  password_enforce_history: "password_enforce_history",
  special_symbol_required: "special_symbol_required"
}

export const ENUMS_CONFIG = {
  method: [
    {
      label: "GET",
      value: "GET"
    },
    {
      label: "POST",
      value: "POST"
    },
    {
      label: "PUT",
      value: "PUT"
    },
    {
      label: "PATCH",
      value: "PATCH"
    },
    {
      label: "DELETE",
      value: "DELETE"
    },
    {
      label: "OPTIONS",
      value: "OPTIONS"
    }
  ],

  service_auth_methods: [
    {
      label: "API Key",
      value: "api_key"
    },
    {
      label: "Basic Auth",
      value: "basic_auth"
    },
    {
      label: "None",
      value: "none"
    }
  ],
  service_protocols: [
    {
      label: "HTTPS",
      value: "https"
    },
    {
      label: "GRPC",
      value: "grpc"
    },
    {
      label: "MQTT",
      value: "mqtt"
    },
    {
      label: "WEBSOCKET",
      value: "websocket"
    }
  ],
  approval_status: [
    {
      label: "In Process",
      value: "in_process"
    },
    {
      label: "Approved",
      value: "approved"
    },
    {
      label: "Rejected",
      value: "rejected"
    }
  ],
  add_to: [
    {
      label: "Header",
      value: "header"
    },
    {
      label: "Query Params",
      value: "query_params"
    }
  ],
  sim_status: [
    {
      label: "Inactive",
      value: "0"
    },
    {
      label: "Active",
      value: "1"
    }
  ],
  sim_connected_status: [
    {
      label: "Disconnect",
      value: "0"
    },
    {
      label: "Connecting",
      value: "1"
    },
    {
      label: "Connected",
      value: "2"
    }
  ],
  device_status: [
    {
      label: "In-stock",
      value: "0",
      color: ""
    },
    {
      label: "Sent",
      value: "1",
      color: "orange"
    },
    {
      label: "Delivered",
      value: "2",
      color: "#108ee9"
    },
    {
      label: "Returning",
      value: "3",
      color: "#f50"
    }
  ],
  receive_device_status: [
    {
      label: "In-transit",
      value: 1,
      color: "orange"
    },
    {
      label: "Received",
      value: 2,
      color: "#108ee9"
    },
    {
      label: "Returned",
      value: 3,
      color: "#f50"
    }
  ],
  vehicle_status: [
    {
      label: "InStock",
      value: 0
    },
    {
      label: "Sent",
      value: 1
    },
    {
      label: "Delivered",
      value: 2
    },
    {
      label: "Returning",
      value: 3
    }
  ],
  organization_type: [
    {
      label: "Tenant",
      value: "1"
    },
    {
      label: "ServiceProvider",
      value: "2"
    },
    {
      label: "Hybrid",
      value: "3"
    }
  ],
  history_action: [
    {
      label: "Create",
      value: "0"
    },
    {
      label: "Update",
      value: "1"
    },
    {
      label: "Update_status",
      value: "2"
    }
  ],
  repeat: [
    {
      label: "Every Year",
      value: 1
    },
    {
      label: "Never",
      value: 0
    }
  ],
  report: [
    { label: "Absence Report", value: 0 },
    { label: "Expense Report", value: 1 }
  ],
  activity_status: [
    { label: "56_6096", value: 0 },
    { label: "56_6097", value: 1 },
    { label: "56_6098", value: 3 },
    { label: "56_6099", value: 4 }
  ]
}

export const SendNotificationType = {
  END_USER: 1,
  PORTAL_USER: 2
}

export const SendNotificationStatus = {
  DRAFT: 1,
  SCHEDULED: 2,
  SENT: 3
}

export const EMAIL_TYPE = {
  Confirm: 1,
  Reset: 2
}

export const ERROR_API_CODE = {
  Resource_Not_Found: "IDM_RNF_404",
  Unable_To_Login: "IDM_IUPWD_400",
  Resource_Deactive: "IDM_RD_423",
  User_Deactive: "IDM_UD_423",
  User_Verify: "IDM_UVY_410",
  User_Not_Found: "IDM_UNF_404",
  User_Locked: "IDM_IULOCKED_400", // user is locked because of logining many times.
  Fail_Parse_Json: "IDM_FTPJB_400",
  Missing_Authorization_Token: "IDM_MAT_404",
  Fail_Validate_Authorization_Token: "IDM_FTVAT_401",
  Fail_Get_Token_Claims: "IDM_FTGTC_400",
  Permission_Not_Allow: "IDM_PNA_403",
  Item_Already_Exists: "IDM_IAE_409",
  Title_Can_Not_Blank: "IDM_TCNBB_400",
  Item_Not_Found: "IDM_INF_404",
  Record_Not_Found: "IDM_RCNF_404",
  Can_Not_Update_Finished_Item: "IDM_CNUFI_400",
  Data_Provided_Not_Correct: "IDM_TDPINC_400",
  Data_Provided_Forbidden: "IDM_TDPIF_403",
  Acccess_Token_Revoked: "IDM_ACT_401",
  Refresh_Token_Revoked: "IDM_RFT_401",
  Password_Incorrect: "IDM_PWDI_400",
  Username_Already_Exists: "IDM_UAE_409",
  Organization_Already_Exists: "IDM_ORGAE_409",
  Service_Provider_Code_Already_Exists: "IDM_SPCAE_409",
  Verification_Confirm_Code_Expired: "IDM_VUCCE_417",
  Verification_Confirm_Code_Invalid: "IDM_VUCCI_400",
  Role_Name_Duplicated: "IDM_RND_409",
  Client_Not_Found: "IDM_CLF_404",
  Invalid_Client_Secret: "IDM_ICS_400",
  ClientID_Taken: "IDM_CIDT_400",
  Authorization_Code_Not_Found: "IDM_ACNF_400",
  Authorization_Code_Expired: "IDM_ACE_417",
  Invalid_RedirectURI: "IDM_IRURL_400",
  Invalid_Scope: "IDM_IC_400",
  Refresh_Token_Not_Found: "IDM_RFTNF_404",
  Refresh_Token_Expired: "IDM_RFTE_401",
  Requested_Scope_Cannot_Be_Greater: "IDM_RSCBG_400",
  Error_When_Substring: "ErrorWhenSubstring"
}
export const PACKAGE_STATUS = {
  module_status: {
    check: 1,
    unchecked: 0
  }
}
export const ticketTypes = [
  {
    label: "Question",
    value: 1
  },
  {
    label: "Problem",
    value: 2
  }
]

export const commenTicketTypes = [
  {
    label: "Created",
    value: 0
  },
  {
    label: "Editer",
    value: 1
  },
  {
    label: "Deleted",
    value: 2
  }
]

export const listBillStatus = [
  {
    label: "Pending",
    value: 0
  },
  {
    label: "Paid",
    value: 1
  },
  {
    label: "Overdue",
    value: 2
  },
  {
    label: "Canceled",
    value: 3
  },
  {
    label: "Refunded",
    value: 4
  }
]

export const listTransactionStatus = [
  {
    label: "Pending",
    value: 0
  },
  {
    label: "Successful",
    value: 1
  },
  {
    label: "Failed",
    value: 2
  }
]
export const listTicketStatus = [
  {
    label: "Unreceived",
    value: 0
  },
  {
    label: "Received",
    value: 1
  },
  {
    label: "Open",
    value: 2
  },
  {
    label: "Reviewing",
    value: 3
  },
  {
    label: "Processing",
    value: 4
  },
  {
    label: "Confirming",
    value: 5
  },
  {
    label: "Closed",
    value: 6
  }
]
export const paymentType = [
  { value: 0, label: "Bank transfer" },
  { value: 1, label: "Cash" },
  { value: 2, label: "Stripe" },
  { value: 3, label: "Pay JP" }
]

export const formatCurrentCode = {
  usd: {
    code: "en-US",
    currency: "USD"
  },
  jpy: {
    code: "ja-JP",
    currency: "JPY"
  }
}
