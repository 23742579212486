import { Button, Dropdown, Tooltip } from "antd"
import { renderTime, getFirstElement } from "lib/function"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

export default function BoxOther({ item, isMyNotification, onMarkUnread, onRemove }) {
  const { t } = useTranslation()
  const { title, created_at, body, is_read, _id, upload_url } = item

  return (
    <div>
      <div className="flex gap-3">
        <div>
          <div>{icNoti}</div>
        </div>
        <div className="w-full grid grid-cols-7 gap-4">
          <div className="col-span-5">
            <Tooltip placement="bottom" title={<div>{title}</div>}>
              <div className="text-[16px] font-bold line-clamp-1">{title}</div>
            </Tooltip>
            <Tooltip
              placement="bottom"
              title={<div dangerouslySetInnerHTML={{ __html: getFirstElement(body) }} />}
            >
              <div
                className="text-[14px] text-[#646970] mt-2 line-clamp-2"
                dangerouslySetInnerHTML={{ __html: getFirstElement(body) }}
              />
            </Tooltip>
          </div>
          <div className="col-span-2">
            <div className="flex justify-end gap-2 items-center">
              <div className="text-xs">{renderTime(dayjs(created_at))}</div>
              {!is_read && <div>{icActive}</div>}
              {isMyNotification && (
                <Dropdown
                  overlay={
                    <div className="shadow_antd w-[240px]">
                      <Button
                        className="w-full !text-start !flex !items-center"
                        type="text"
                        icon={icTick}
                        onClick={(e) => {
                          e.stopPropagation()
                          onMarkUnread(_id)
                        }}
                      >
                        {t("Mark as unread")}
                      </Button>
                      <Button
                        className="w-full !text-start !flex !items-center"
                        type="text"
                        icon={icClose}
                        onClick={(e) => {
                          e.stopPropagation()
                          onRemove(_id)
                        }}
                      >
                        {t("Remove this notification")}
                      </Button>
                    </div>
                  }
                >
                  <Button className="!p-[4px]" type="text" onClick={(e) => e.stopPropagation()}>
                    {icMore}
                  </Button>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      </div>
      {isMyNotification &&
        upload_url &&
        typeof upload_url === "object" &&
        upload_url.length > 0 && (
          <div className="col-span-7 mt-2">
            <div className="ml-[50px] flex flex-wrap gap-4">
              {upload_url.map((v, index) => (
                <Link key={index} to={v?.url} target="_blank" onClick={(e) => e.stopPropagation()}>
                  <div className="flex items-center gap-1 max-w-[200px]">
                    <span>{icLink}</span>
                    <Tooltip title={v?.name}>
                      <span className="line-clamp-1 !text-[#4DABD1]">{v?.name}</span>
                    </Tooltip>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
    </div>
  )
}

const icActive = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.25" y="1.25" width="11.5" height="11.5" rx="5.75" fill="#4DABD1" />
    <rect x="1.25" y="1.25" width="11.5" height="11.5" rx="5.75" stroke="white" strokeWidth="1.5" />
  </svg>
)
const icMore = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 2.98438C7 3.24959 7.10536 3.50395 7.29289 3.69148C7.48043 3.87902 7.73478 3.98438 8 3.98438C8.26522 3.98438 8.51957 3.87902 8.70711 3.69148C8.89464 3.50395 9 3.24959 9 2.98438C9 2.71916 8.89464 2.4648 8.70711 2.27727C8.51957 2.08973 8.26522 1.98438 8 1.98438C7.73478 1.98438 7.48043 2.08973 7.29289 2.27727C7.10536 2.4648 7 2.71916 7 2.98438ZM7 7.98438C7 8.24959 7.10536 8.50395 7.29289 8.69148C7.48043 8.87902 7.73478 8.98438 8 8.98438C8.26522 8.98438 8.51957 8.87902 8.70711 8.69148C8.89464 8.50395 9 8.24959 9 7.98438C9 7.71916 8.89464 7.4648 8.70711 7.27727C8.51957 7.08973 8.26522 6.98438 8 6.98438C7.73478 6.98438 7.48043 7.08973 7.29289 7.27727C7.10536 7.4648 7 7.71916 7 7.98438ZM7 12.9844C7 13.2496 7.10536 13.5039 7.29289 13.6915C7.48043 13.879 7.73478 13.9844 8 13.9844C8.26522 13.9844 8.51957 13.879 8.70711 13.6915C8.89464 13.5039 9 13.2496 9 12.9844C9 12.7192 8.89464 12.4648 8.70711 12.2773C8.51957 12.0897 8.26522 11.9844 8 11.9844C7.73478 11.9844 7.48043 12.0897 7.29289 12.2773C7.10536 12.4648 7 12.7192 7 12.9844Z"
      fill="#646970"
    />
  </svg>
)
const icTick = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9296 2.8125H17.3694C17.1506 2.8125 16.943 2.91295 16.8091 3.08482L7.60597 14.7433L3.19302 9.15179C3.12627 9.06703 3.04118 8.9985 2.94414 8.95133C2.8471 8.90417 2.74065 8.8796 2.63276 8.87946H1.07249C0.922934 8.87946 0.840344 9.05134 0.931862 9.16741L7.0457 16.9129C7.33142 17.2746 7.88052 17.2746 8.16847 16.9129L19.0703 3.09821C19.1618 2.98438 19.0792 2.8125 18.9296 2.8125V2.8125Z"
      fill="#646970"
    />
  </svg>
)
const icClose = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1562 9.99895L17.0155 3.01458C17.1137 2.89851 17.0312 2.72217 16.8794 2.72217H15.0981C14.9932 2.72217 14.8928 2.76904 14.8236 2.8494L9.99097 8.61056L5.15838 2.8494C5.09142 2.76904 4.99097 2.72217 4.88383 2.72217H3.10258C2.95079 2.72217 2.8682 2.89851 2.96642 3.01458L8.82579 9.99895L2.96642 16.9833C2.94442 17.0092 2.9303 17.0408 2.92575 17.0745C2.9212 17.1082 2.9264 17.1424 2.94073 17.1732C2.95507 17.204 2.97794 17.23 3.00663 17.2482C3.03532 17.2664 3.06862 17.2759 3.10258 17.2757H4.88383C4.98874 17.2757 5.08919 17.2289 5.15838 17.1485L9.99097 11.3873L14.8236 17.1485C14.8905 17.2289 14.991 17.2757 15.0981 17.2757H16.8794C17.0312 17.2757 17.1137 17.0994 17.0155 16.9833L11.1562 9.99895Z"
      fill="#646970"
    />
  </svg>
)
const icLink = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3832 13.4224C11.3497 13.3892 11.3043 13.3706 11.2571 13.3706C11.2099 13.3706 11.1646 13.3892 11.131 13.4224L8.53725 16.0162C7.33636 17.2171 5.30957 17.3443 3.98368 16.0162C2.65555 14.6881 2.78278 12.6635 3.98368 11.4626L6.57743 8.86886C6.64662 8.79967 6.64662 8.68583 6.57743 8.61663L5.68903 7.72824C5.65547 7.695 5.61015 7.67636 5.56292 7.67636C5.51569 7.67636 5.47036 7.695 5.4368 7.72824L2.84305 10.322C0.95466 12.2104 0.95466 15.2662 2.84305 17.1523C4.73145 19.0385 7.78725 19.0407 9.67341 17.1523L12.2672 14.5586C12.3364 14.4894 12.3364 14.3756 12.2672 14.3064L11.3832 13.4224ZM17.1556 2.84208C15.2672 0.953683 12.2114 0.953683 10.3252 2.84208L7.72921 5.43583C7.69598 5.46939 7.67734 5.51471 7.67734 5.56194C7.67734 5.60917 7.69598 5.6545 7.72921 5.68806L8.61537 6.57422C8.68457 6.64342 8.79841 6.64342 8.86761 6.57422L11.4614 3.98047C12.6622 2.77958 14.689 2.65234 16.0149 3.98047C17.3431 5.30859 17.2158 7.33315 16.0149 8.53404L13.4212 11.1278C13.3879 11.1614 13.3693 11.2067 13.3693 11.2539C13.3693 11.3011 13.3879 11.3465 13.4212 11.38L14.3096 12.2684C14.3788 12.3376 14.4926 12.3376 14.5618 12.2684L17.1556 9.67467C19.0417 7.78627 19.0417 4.73047 17.1556 2.84208ZM12.189 6.88002C12.1555 6.84679 12.1102 6.82815 12.0629 6.82815C12.0157 6.82815 11.9704 6.84679 11.9368 6.88002L6.881 11.9336C6.84777 11.9672 6.82912 12.0125 6.82912 12.0597C6.82912 12.1069 6.84777 12.1523 6.881 12.1858L7.76493 13.0698C7.83412 13.139 7.94796 13.139 8.01716 13.0698L13.0707 8.01618C13.1399 7.94699 13.1399 7.83315 13.0707 7.76395L12.189 6.88002Z"
      fill="#646970"
    />
  </svg>
)
const icNoti = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#E3F1F8" />
    <rect x="4" y="4" width="32" height="32" rx="16" fill="#8DC9E1" />
    <path
      d="M26.7857 25.7115H26.25V18.1222C26.25 14.9727 23.9219 12.37 20.8929 11.9369V11.0686C20.8929 10.5753 20.4933 10.1758 20 10.1758C19.5067 10.1758 19.1071 10.5753 19.1071 11.0686V11.9369C16.0781 12.37 13.75 14.9727 13.75 18.1222V25.7115H13.2143C12.8192 25.7115 12.5 26.0307 12.5 26.4258V27.1401C12.5 27.2383 12.5804 27.3186 12.6786 27.3186H17.5C17.5 28.6981 18.6205 29.8186 20 29.8186C21.3795 29.8186 22.5 28.6981 22.5 27.3186H27.3214C27.4196 27.3186 27.5 27.2383 27.5 27.1401V26.4258C27.5 26.0307 27.1808 25.7115 26.7857 25.7115ZM20 28.3901C19.4085 28.3901 18.9286 27.9102 18.9286 27.3186H21.0714C21.0714 27.9102 20.5915 28.3901 20 28.3901ZM15.3571 25.7115V18.1222C15.3571 16.8811 15.8393 15.716 16.7165 14.8387C17.5938 13.9615 18.7589 13.4794 20 13.4794C21.2411 13.4794 22.4063 13.9615 23.2835 14.8387C24.1607 15.716 24.6429 16.8811 24.6429 18.1222V25.7115H15.3571Z"
      fill="#E3F1F8"
    />
  </svg>
)
