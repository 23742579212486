const key = {
  SET_AUTH: "SET_AUTH",
  LOG_OUT: "LOG_OUT",
  SET_PROJECT: "SET_PROJECT",
  SET_LOCALE: "SET_LOCALE",
  CALLING: "CALLING",
  PACKAGE_TEMPLATE: "PACKAGE_TEMPLATE"
}

export default key
