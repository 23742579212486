import Client from "../client/Client"
import version from "../version"
const resource = version + "/staff/domains"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function checkDuplicate(data) {
  return Client.post(`${resource}/duplicated/`, data)
}
function updateSubDomain(id, data) {
  return Client.put(`${resource}/${id}/subdomain/`, data)
}
function changeStatus(id, data) {
  return Client.put(`${resource}/${id}/change-status/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/${id}/`)
}

const api = {
  gets,
  checkDuplicate,
  updateSubDomain,
  changeStatus,
  remove
}
export default api
