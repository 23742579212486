import { useReducer, useMemo, createContext, useContext } from "react"
import {
  setLocalProject,
  getBaseDataLocal,
  removeAuthLocal,
  setBaseDataLocal
} from "lib/localstorage"
import { removeCookies } from "lib/cookie"
import KEY from "./Const"

function initialState() {
  let baseData = getBaseDataLocal()
  return {
    ...baseData,
    packageTemplates: false,
    locale: "jp"
  }
}

function reducer(state, action) {
  switch (action.type) {
    case KEY.SET_AUTH:
      return { ...state, ...action.value }
    case KEY.SET_PROJECT:
      return { ...state, project: action.value }
    case KEY.SET_LOCALE:
      return { ...state, locale: action.value }
    case KEY.PACKAGE_TEMPLATE:
      return { ...state, packageTemplates: action.value }
    case KEY.LOG_OUT:
      removeCookies()
      removeAuthLocal()
      return initialState()
    default:
      throw new Error()
  }
}

const MyContext = createContext(initialState())
MyContext.displayName = "MyContext"

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState())

  const setAuthStore = (value) => {
    const currentBaseData = getBaseDataLocal()
    const newValua = { ...currentBaseData, ...value }
    localStorage.setItem("isSignIn", 1)
    setBaseDataLocal(newValua)
    return dispatch({ type: KEY.SET_AUTH, value })
  }
  const logOut = () => {
    dispatch({ type: KEY.LOG_OUT })
  }

  const setProject = (value) => {
    setLocalProject(value)
    return dispatch({ type: KEY.SET_PROJECT, value })
  }
  const setLocale = (value) => {
    return dispatch({ type: KEY.SET_LOCALE, value })
  }
  const setPackageTemplate = (value) => {
    return dispatch({ type: KEY.PACKAGE_TEMPLATE, value })
  }
  const value = useMemo(
    () => ({
      ...state,
      setAuthStore,
      setProject,
      setPackageTemplate,
      logOut,
      setLocale
    }),
    [state]
  )
  return <MyContext.Provider value={value} {...props} />
}

const useStore = () => {
  const context = useContext(MyContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

export default useStore
