import { Suspense, useEffect } from "react"
import { Outlet, BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"
import Layout from "components/common/Layout"
import { Result } from "antd"
import SuspenseComponent from "./SuspenseComponent"
import pageList from "./PageList"
import Login from "pages/Authen/Main"
import ForgotPassword from "pages/Authen/ForgotPassword"
import MaintenancePage from "pages/MaintenancePage"
import PageNotFound from "pages/ErrorPage/PageNotFound"
import PageServiceUnavailable from "pages/ErrorPage/PageServiceUnavailable"
import { useStore } from "components/ui"
import { useTranslation } from "react-i18next"

function ProtectedRoute() {
  return (
    <Layout>
      <Suspense fallback={<SuspenseComponent />}>
        <Outlet />
      </Suspense>
    </Layout>
  )
}

export default function Routers() {
  const { access_token, permission } = useStore()
  return (
    <Router>
      <Routes>
        {access_token && permission ? (
          <>
            <Route element={<ProtectedRoute />}>
              {pageList().map(({ Element, path, resource, pageName }, key) => {
                const isView = !!permission.find((i) => i === resource)
                return (
                  <Route
                    path={path}
                    key={key}
                    element={isView ? <Element /> : <NotAuthorization />}
                  />
                )
              })}
              <Route path="/maintenance" element={<MaintenancePage />} />
              <Route path="/error-5xx" element={<PageServiceUnavailable />} />
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </>
        ) : (
          <Route element={<Outlet />}>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="*" element={<Login />} />
          </Route>
        )}
      </Routes>
    </Router>
  )
}

const NotAuthorization = () => {
  const { t } = useTranslation()
  return (
    <div className="__content">
      <Result
        status="403"
        title="403"
        subTitle={t("Sorry, you are not authorized to access this page.")}
        extra={<Link to="/">{t("Back Home")}</Link>}
      />
    </div>
  )
}
