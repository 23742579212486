import Collapse from "components/Collapse"
import { useEffect, useState } from "react"
import { useNavigate, NavLink, useLocation } from "react-router-dom"
import { apiUser } from "api"
import { getCookieAuth } from "lib/cookie"
import { useStore } from "components/ui"
import confirmLogoutImage from "assets/images/confirmLogoutImage.png"
import { Image, Button, Modal, Tooltip } from "antd"
import cn from "classnames"
import { togleMenu } from "lib/icons"
import { useTranslation } from "react-i18next"

export default function Navbar({ items, togleSidebar, isNav, settingTheme }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { logOut } = useStore()
  const [isModalOpen, setIsModalOpen] = useState(false)

  async function handleLogout() {
    try {
      await apiUser.logout({
        refresh_token: getCookieAuth()?.refresh_token
      })
    } catch (error) {
      console.log({ error })
    } finally {
      logOut()
      navigate("/login")
    }
  }

  useEffect(() => {
    if (isNav === "left-0") {
      if (document.body.style.overflow !== "hidden") {
        document.body.style.overflow = "hidden"
      }
    }
    return () => {
      document.body.style.overflow = null
      document.body.style.width = null
    }
  }, [isNav])

  const wapperClass = isNav ? "show__sider" : "hide__sider"
  const classTogleMenu = isNav ? "text-white right-2" : "text-dark-500 -right-10"
  return (
    <div
      className={`bg-primary-700 z-30 sticky h-screen border-r border-primary-300 top-0 shadow ease-in-out duration-300 ${wapperClass}`}
    >
      <div
        className={
          "relative border-b flex justify-between items-center border-primary-300 px-4 h-[63px] text-xl font-bold text-primary-50"
        }
      >
        <div className={cn("flex items-center", { "gap-4": isNav }, { "pl-1": !isNav })}>
          <img
            src={settingTheme?.logo}
            alt="logo"
            className={`w-[42px] h-[42px] rounded`}
            onClick={() => navigate("/dashboard")}
          />
          {isNav && (
            <Tooltip title={settingTheme.name}>
              <div className="text-ellipsis overflow-hidden whitespace-nowrap max-w-[180px]">
                {settingTheme.name}
              </div>
            </Tooltip>
          )}
        </div>

        <div
          onClick={togleSidebar}
          className={`absolute z-40  top-4 cursor-pointer p-2 ani rounded transition-all duration-300 ${classTogleMenu}`}
        >
          {togleMenu}
        </div>
      </div>
      <div className={isNav ? "overflow-y-auto __scroll" : ""}>
        <div className="flex flex-col py-4">
          <ul className="space-y-2">
            {items.map((item) => {
              const { url, title, icon, children } = item
              const isCurrent = location.pathname.includes(url)
              if (children && children.length > 0) {
                return <MenuItem {...item} key={url} location={location} isNav={isNav} t={t} />
              } else {
                const activeClassName = isCurrent
                  ? "bg-primary-50 text-primary-600 font-bold hover:bg-primary-300"
                  : "text-primary-200 hover:bg-white/30"
                return (
                  <li key={url} className="px-4 mb-2 group relative dropdown">
                    <NavLink to={url}>
                      <div className={`${activeClassName} menu__item`}>
                        {icon}
                        <span className="text-ellipsis flex-auto overflow-hidden whitespace-nowrap">
                          {t(title)}
                        </span>
                      </div>
                    </NavLink>
                    {!isNav && (
                      <div className="group-hover:block dropdown-menu absolute top-[4px] left-[65px] hidden h-auto">
                        <div className="flex">
                          <div className="w-[25px] bg-transparent"></div>
                          <div class="top-0 bg-primary-700 shadow rounded-lg py-2 px-4 text-white text-ellipsis flex-auto whitespace-nowrap">
                            {t(title)}
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                )
              }
            })}
          </ul>
        </div>
      </div>

      <ModalConfirmLogout
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSubmit={handleLogout}
      />
    </div>
  )
}

function MenuItem({ title, icon, url, children, location, isNav, t }) {
  const [isOpen, setOpen] = useState(children.find((i) => location.pathname.includes(i.url)))
  const isCurrent = location.pathname.includes(url)
  function onTogle() {
    setOpen((c) => !c)
  }
  const activeClassName = isCurrent
    ? "bg-primary-50 text-primary-600 font-bold hover:bg-primary-200"
    : "text-primary-200 hover:bg-white/30"
  const classIsDown = isOpen ? "rotate-180" : "rotate-0"

  return (
    <div className="sub__menu relative">
      <li className="px-4">
        <div
          onClick={() => isNav && onTogle()}
          className={`${activeClassName} rounded cursor-pointer
            h-10 px-4 flex items-center justify-between gap-4
            group relative dropdown
          `}
        >
          <div className="flex w-full items-center gap-4">
            {icon}
            <span className="text-ellipsis flex-auto overflow-hidden whitespace-nowrap">
              {t(title)}
            </span>
          </div>
          {isNav ? (
            <div className={`${classIsDown} flex items-center transition-all duration-300`}>
              {icDown}
            </div>
          ) : (
            <div className="group-hover:block dropdown-menu absolute top-[0px] left-[50px] hidden h-auto">
              <div className="flex">
                <div className="w-[25px] bg-transparent"></div>
                <ul class="top-0 w-60 bg-primary-700 shadow rounded-lg p-4">
                  {children.map((item) => {
                    return (
                      <li className="!mt-0" key={`${item.url}-${url}`}>
                        <NavLink
                          to={`${item.url}`}
                          className={({ isActive }) =>
                            isActive
                              ? "flex items-center text-primary-50 gap-4 py-2 px-4 border-l-[2px] border-primary-50 __active__sub__menu"
                              : "flex items-center text-primary-300 gap-4 py-2 px-4 border-l-[2px] border-primary-300"
                          }
                        >
                          <Tooltip
                            overlayInnerStyle={{ width: "max-content" }}
                            title={
                              item?.url === "/user/users" ? (
                                <div>{t("List of user accounts managed by Super admin")}</div>
                              ) : null
                            }
                            placement="right"
                          >
                            <span className="text-ellipsis flex items-center gap-2 flex-auto overflow-hidden whitespace-nowrap">
                              {t(item.title)}
                            </span>
                          </Tooltip>
                        </NavLink>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
      </li>
      <div className="h-[1px]"></div>
      <Collapse isOpen={isOpen} isShow={isNav}>
        <ul className="p-2 pl-4 space-y-2 bg-white/10 rounded">
          {children.map((item) => {
            return (
              <li className="!mt-0" key={`${item.url}-${url}`}>
                <NavLink
                  to={`${item.url}`}
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center text-primary-50 gap-4 py-2 h-[40px] px-4 border-l-[2px] border-primary-50 __active__sub__menu"
                      : "flex items-center text-primary-300 gap-4 py-2 h-[40px] px-4 border-l-[2px] border-primary-300"
                  }
                >
                  <Tooltip
                    overlayInnerStyle={{ width: "max-content" }}
                    title={
                      item?.url === "/user/users" ? (
                        <div>{t("List of user accounts managed by Super admin")}</div>
                      ) : null
                    }
                    placement="right"
                  >
                    <span className="text-ellipsis flex items-center gap-2 flex-auto overflow-hidden whitespace-nowrap">
                      {t(item.title)}
                    </span>
                  </Tooltip>
                </NavLink>
              </li>
            )
          })}
        </ul>
      </Collapse>
    </div>
  )
}

function ModalConfirmLogout({ onSubmit, isModalOpen, setIsModalOpen }) {
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Modal className="!max-w-[400px]" open={isModalOpen} onCancel={handleCancel} footer={<></>}>
      <div className="flex justify-center mt-10">
        <Image width={151} height={117} preview={false} src={confirmLogoutImage} />
      </div>
      <h3 className="text-[20px] font-bold text-[#1C3E4B] text-center mt-8 mb-3">Confirm logout</h3>
      <p className="text-[#646970] text-center mb-5">Are you sure you want to log out?</p>

      <div className="flex justify-between gap-4">
        <Button size="large" className="!rounded-lg w-full" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          type="primary"
          danger
          size="large"
          className="!rounded-lg w-full"
          onClick={onSubmit}
        >
          Log out
        </Button>
      </div>
    </Modal>
  )
}

const icDown = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1315 4.6875H2.86897C2.56116 4.6875 2.38929 5.0125 2.57991 5.23438L7.71116 11.1844C7.85804 11.3547 8.14085 11.3547 8.28929 11.1844L13.4205 5.23438C13.6112 5.0125 13.4393 4.6875 13.1315 4.6875Z"
      fill="currentColor"
    />
  </svg>
)
