import Client from "../client/Client"
import version from "../version"
const resource = version + "/staff"

function getALL(params) {
  return Client.get(`${resource}/billings/`, { params })
}
function gets(id, params) {
  return Client.get(`${resource}/${id}/billings/`, { params })
}
function getTransactions(id, params) {
  return Client.get(`${resource}/billings/${id}/transactions/`, { params })
}
function getLatest(id, params) {
  return Client.get(`${resource}/${id}/billings/latest`, { params })
}
function get(id, params) {
  return Client.get(`${resource}/billings/${id}/`, { params })
}
function exportList(data) {
  return Client.post(`${resource}/billings/export-csv/`, data)
}
function create(billing_id, data) {
  return Client.post(`${resource}/billings/${billing_id}/payment-methods/`, data)
}
function updatePackage(id, data) {
  return Client.put(`${resource}/clients/${id}/change-package/`, data)
}

function getBillingFromClient(client_id, params) {
  return Client.get(`${resource}/clients/${client_id}/billing/`, { params })
}
function updateStatus(billing_id, data) {
  return Client.put(`${resource}/billings/${billing_id}/change-status/`, data)
}
function updateStatusTransaction(billing_id, transaction_id, data) {
  return Client.put(
    `${resource}/billings/${billing_id}/transactions/${transaction_id}/change-status/`,
    data
  )
}
function exportListTransaction(billing_id, data) {
  return Client.post(`${resource}/billings/${billing_id}/transactions/export-csv/`, data)
}
function getBillingAddress(id) {
  return Client.get(`${resource}/clients/${id}/billing/setting/`)
}
function updateBillingAddress(id, data) {
  return Client.put(`${resource}/clients/${id}/billing/setting/`, data)
}
function getStats(params) {
  return Client.get(`${resource}/billings/stats/`, { params })
}
function invoice(id, params) {
  return Client.get(`${resource}/billings/${id}/invoice/`, { params })
}
function removePacakgeUpcoming(id) {
  return Client.put(`${resource}/clients/${id}/remove-upcoming-package/`)
}
const api = {
  getALL,
  gets,
  getLatest,
  get,
  exportList,
  exportListTransaction,
  create,
  updatePackage,
  getBillingFromClient,
  getTransactions,
  updateStatus,
  updateStatusTransaction,
  updateBillingAddress,
  getBillingAddress,
  getStats,
  invoice,
  removePacakgeUpcoming
}

export default api
